 body {
  text-align: center;
  background-color: lightblue;
}

.App-logo {
  height: 40vmin;
  margin: 50px;
}

.App-image {
  height: 60vmin;
  margin: 50px;
}

.Nav-image {
  height: 10vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  background-color: lightblue;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; /* Add this line */
  
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


a {
  text-decoration: none;

}

.inputField {
  margin: 50px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  width: 200px;
  background-color: white;
}

.inputFieldContainer {
  margin: 20px;
}

.pageBox {
  margin-top: 5%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid black;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageBox:hover {
  background-color: lightgray; /* Change background color on hover */
}



.wheel-container {
  position: relative;
}

.gameImage {
  width: 80%; /* Good size for small devices */
  max-width: 100%; /* Ensures image is not bigger than its container */
  height: auto; /* Maintain aspect ratio */
  border-radius: 90%;
  display: block; /* Ensures the image is centered */
  margin: 0 auto; /* Center the image */
}

/* Media queries for larger screens */
@media (min-width: 768px) {
  .gameImage {
    width: 50%; /* Larger than on mobile, but not too big */
  }
}

@media (min-width: 1024px) {
  .gameImage {
    width: 30%; /* Ideal for desktops and large tablets */
  }
}

.question-container {
  width: 95%; /* Default width for smaller devices like phones */
  margin: auto; /* Centers the container */
}

/* Media query for devices with a width of 768px or more (typically tablets and desktops) */
@media (min-width: 768px) {
  .question-container {
    width: 60%; /* Adjusted width for larger devices like PCs */
  }
}